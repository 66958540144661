@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic");

/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
* {
  margin: 0;
  padding: 0;
}

html {
  margin: 0 !important;
  overflow: scroll !important;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

body {
  color: $font-color;
  background-color: $color-light;
  font-family: $font-family;
  font-size: $font-size-normal;
  line-height: 1.7;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

.relative {
  position: relative;
}
