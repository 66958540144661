$font-awesome: "Font Awesome 5 Free";

/*=================================================================*/
/*                      ELEMENTS
/*=================================================================*/
/* === Timeline === */
.timeline {
  position: relative;

  .timeline-container {
    padding-left: 50px;
    margin-bottom: 50px;
    position: relative;
    background-color: inherit;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  
  .content {
    position: relative;

    .time {
      color: #8B88B1;
      font-size: $font-size-smaller;
    }

    h3 {
      font-size: 20px;
      margin: 10px 0;
    }

    p {
      margin: 0;
    }
  }

  span {
    &.line {
      position: absolute;
      width: 1px;
      background-color: $color-primary;
      top: 30px;
      bottom: 30px;
      left: 34px;
    }
  }
}

/* === Social Icons === */
.social-icons {
  li:not(:last-child) {
    margin-right: 1.5rem;
  }

  li {
    a {
      font-size: 21px;
    }
  }
}

/* === Helper === */
.rounded {
  border-radius: 20px !important;
}

.bg-white {
  background: $color-white;
}

.shadow-dark, .header .dropdown-menu, .form-control, .form-control:focus {
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.shadow-light {
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
  box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
}

.shadow-pink {
  box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
}

.shadow-yellow {
  box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
}

.padding-30 {
  padding: 30px;
}

.triangle-left {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid $color-white;
    position: absolute;
    left: -15px;
    top: 20%;
  }
}

.triangle-top {
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color-white;
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-7.5px);
  }
}

/* === Progress Bar === */
.progress {
  height: 7px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #F1F1F1;
  border-radius: 15px;
  box-shadow: none;
}

.progress-bar {
  border-radius: 15px;
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 7px;
  color: $color-white;
  text-align: center;
  background-color: $color-dark;
  box-shadow: none;
  transition: width 0.6s ease;
}

/*=================================================================*/
/*                      FACTS                              
/*=================================================================*/
.fact-item {
  .details {
    margin-left: 60px;
  }

  .icon {
    font-size: $font-size-largest;
    color: #dedeea;
    float: left;
  }

  .number {
    font-size: 30px;

    em {
      font-style: normal;
    }
  }

  p {
    font-size: $font-size-normal;
  }
}

/* === Go to Top === */
#return-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
  border-radius: 100%;
  display: none;
  z-index: 4;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  svg {
    color: $color-white;
    margin: 0;
    position: relative;
    left: 13px;
    top: 8px;
    font-size: 16px;
    transform: translateY(0px);
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    background: $color-primary;
  }
}