/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
  background-image: url("../../../public/images/map.svg");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 200px;
}
.contact-info h3 {
  font-size: 23px;
  margin: 0 0 10px;
}

/* === Form Control === */

.form-control, .form-control:focus {
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.form-control {
  display: block;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #454360;
  background-color: #FFF;
  background-image: none;
  border: 0;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: #AEACCA;
  }
}

.form-group {
  margin-bottom: 30px;
}

/* === Email Sent === */

.st0{fill:#342454;}
.st1{fill:#E6EBE0;}
.st2{fill:#7b6f90;}
.st3{fill:#f84d64;}
.st4{fill:#5b436e;}

#mail-icon {
  width:170px;
  position: relative;
  margin: 0.5em 35% 0.5em 65%;
}